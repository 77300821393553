<template>
  <CreateUpdateTemplate
    custom-class="knowledge-base-create"
    v-if="getPermission('knowledge-base:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <span v-if="kbId">Update</span
        ><span v-else>Create new</span>&nbsp;Knowledge Base
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="kbForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="12">
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td width="20%">
                    <label
                      class="font-weight-700 font-size-16 my-0 py-0"
                      for="kb-subject"
                      >Subject</label
                    >
                  </td>
                  <td width="80%">
                    <v-text-field
                      v-model="kbCreate.subject"
                      dense
                      filled
                      label="Subject"
                      solo
                      flat
                      id="kb-subject"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(kbCreate.subject, 'Subject'),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td width="20%">
                    <label
                      class="font-weight-700 font-size-16 my-0 py-0"
                      for="kb-group"
                      >Group</label
                    >
                  </td>
                  <td width="80%">
                    <v-text-field
                      v-model="kbCreate.group"
                      dense
                      filled
                      label="Group"
                      solo
                      flat
                      id="kb-group"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td width="20%" valign="top" class="py-4">
                    <label
                      class="font-weight-700 font-size-16 my-0 py-0"
                      for="kb-description"
                      >Description</label
                    >
                  </td>
                  <td width="80%">
                    <editor
                      id="kb-description"
                      v-model="kbCreate.description"
                    />
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  PATCH,
  GET,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "knowledge-base-create",
  title: "Create Knowledge Base",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      kbId: 0,
      kbCreate: new Object({
        reference: null,
        subject: null,
        group: null,
        description: null,
      }),
    };
  },
  components: {
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.kbForm.validate();

      const formErrors = _this.validateForm(_this.$refs.kbForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.kbCreate.reference,
        subject: _this.kbCreate.subject,
        group: _this.kbCreate.group,
        description: _this.kbCreate.description,
      });

      let requestType = POST;
      let requestURL = "knowledge-base";
      if (_this.kbId > 0) {
        requestType = PATCH;
        requestURL = `knowledge-base/${_this.kbId}`;
      }

      _this.$store
        .dispatch(requestType, {
          url: requestURL,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("knowledge.base.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getKB() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "knowledge-base/" + _this.kbId })
        .then(({ data }) => {
          _this.kbCreate.reference = data.reference;
          _this.kbCreate.subject = data.subject;
          _this.kbCreate.group = data.group;
          _this.kbCreate.description = data.description;
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Knowledge Base", route: "knowledge-base" },
            { title: "Update" },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Knowledge Base", route: "knowledge.base" },
      { title: "Create" },
    ]);

    _this.kbId = ObjectPath.get(_this.$route, "params.id", 0);

    if (_this.kbId > 0) {
      _this.getKB();
    }
  },
};
</script>
